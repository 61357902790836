
export const IMAGE_PATH = "https://api.helperzz.com/public/uploads/"

export const SESSION_USER = "HELPERZZZ_ADMIN_USER"

//REDUCERS
export const CATEGORY_REDUCER = "CATEGORY_REDUCER"
export const SUBCATEGORY_REDUCER = "SUBCATEGORY_REDUCER"
export const USER_REDUCER = "USER_REDUCER"
export const BLOG_REDUCER = "BLOG_REDUCER"
export const COST_GUIDE_REDUCER = "COST_GUIDE_REDUCER"
export const CONTRACTOR_REDUCER = "CONTRACTOR_REDUCER"
export const CONTRACTOR_REQUEST_REDUCER = "CONTRACTOR_REQUEST_REDUCER"
export const TESTIMONIAL_REDUCER = "TESTIMONIAL_REDUCER"
export const REQUESTS_REDUCER = "REQUESTS_REDUCER"
export const CUSTOMER_REDUCER = "CUSTOMER_REDUCER"
export const CITY_REDUCER = "CITY_REDUCER"
export const HIGHLIGHT_REDUCER = "HIGHLIGHT_REDUCER"
export const LANGUAGE_REDUCER = "LANGUAGE_REDUCER"
export const REVIEW_REDUCER = "REVIEW_REDUCER"
export const STORY_REDUCER = "STORY_REDUCER"


//CATEGORY
export const ALL_CATEGORIES = "ALL_CATEGORIES"
export const SINGLE_CATEGORY = "SINGLE_CATEGORY"
export const ACTIVE_CATEGORIES = "ACTIVE_CATEGORIES"
export const CREATE_CATEGORY = "CREATE_CATEGORY"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const STATUS_CATEGORY = "STATUS_CATEGORY"
export const FEATURE_CATEGORY = "FEATURE_CATEGORY"
export const POPULAR_CATEGORY = "POPULAR_CATEGORY"
export const BANNER_CATEGORY = "BANNER_CATEGORY"


//SUBCATEGORY
export const ALL_SUBCATEGORIES = "ALL_SUBCATEGORIES"
export const SINGLE_SUBCATEGORY = "SINGLE_SUBCATEGORY"
export const CREATE_SUBCATEGORY = "CREATE_SUBCATEGORY"
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY"
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY"
export const STATUS_SUBCATEGORY = "STATUS_SUBCATEGORY"


//TESTIMONIAL
export const ALL_REVIEWS = "ALL_REVIEWS"
export const APPROVE_REVIEW = "APPROVE_REVIEW"
export const REJECT_REVIEW = "REJECT_REVIEW"
export const DELETE_REVIEW = "DELETE_REVIEW"


//TESTIMONIAL
export const ALL_TESTIMONIALS = "ALL_TESTIMONIALS"
export const SINGLE_TESTIMONIAL = "SINGLE_TESTIMONIAL"
export const CREATE_TESTIMONIAL = "CREATE_TESTIMONIAL"
export const UPDATE_TESTIMONIAL = "UPDATE_TESTIMONIAL"
export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL"
export const STATUS_TESTIMONIAL = "STATUS_TESTIMONIAL"
export const FEATURE_TESTIMONIAL = "FEATURE_TESTIMONIAL"

//Contactus
export const CONTACT_US = "CONTACT_US"


//SEVICE REQUESTS
export const ALL_REQUESTS = "ALL_REQUESTS"
export const RECENT_REQUESTS = "RECENT_REQUESTS"
export const SINGLE_REQUEST = "SINGLE_REQUEST"
export const ACCEPT_REQUEST = "ACCEPT_REQUEST"
export const REJECT_REQUEST = "REJECT_REQUEST"
export const DELETE_REQUEST = "DELETE_REQUEST"
export const ASSIGN_CONTRACTOR = "ASSIGN_CONTRACTOR"


//CUSTOMERS
export const ALL_CUSTOMERS = "ALL_CUSTOMERS"
export const STATUS_CUSTOMER = "STATUS_CUSTOMER"


//USERS
export const ALL_USERS = "ALL_USERS"
export const SINGLE_USER = "SINGLE_USER"
export const CREATE_USER = "CREATE_USER"
export const UPDATE_USER = "UPDATE_USER"
export const DELETE_USER = "DELETE_USER"
export const STATUS_USER = "STATUS_USER"


//CITIES
export const ALL_CITIES = "ALL_CITIES"
export const SINGLE_CITY = "SINGLE_CITY"
export const CREATE_CITY = "CREATE_CITY"
export const UPDATE_CITY = "UPDATE_CITY"
export const DELETE_CITY = "DELETE_CITY"


//HIGHLIGHTS
export const ALL_HIGHLIGHTS = "ALL_HIGHLIGHTS"
export const SINGLE_HIGHLIGHT = "SINGLE_HIGHLIGHT"
export const CREATE_HIGHLIGHT = "CREATE_HIGHLIGHT"
export const UPDATE_HIGHLIGHT = "UPDATE_HIGHLIGHT"
export const DELETE_HIGHLIGHT = "DELETE_HIGHLIGHT"


//LANGUAGES
export const ALL_LANGUAGES = "ALL_LANGUAGES"
export const SINGLE_LANGUAGE = "SINGLE_LANGUAGE"
export const CREATE_LANGUAGE = "CREATE_LANGUAGE"
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE"
export const DELETE_LANGUAGE = "DELETE_LANGUAGE"


//SUCCESS STORY
export const ALL_STORIES = "ALL_STORIES"
export const SINGLE_STORY = "SINGLE_STORY"
export const CREATE_STORY = "CREATE_STORY"
export const UPDATE_STORY = "UPDATE_STORY"
export const DELETE_STORY = "DELETE_STORY"
export const STATUS_STORY = "STATUS_STORY"
export const POPULAR_STORY = "POPULAR_STORY"


//BLOGS
export const ALL_BLOGS = "ALL_BLOGS"
export const SINGLE_BLOG = "SINGLE_BLOG"
export const CREATE_BLOG = "CREATE_BLOG"
export const UPDATE_BLOG = "UPDATE_BLOG"
export const DELETE_BLOG = "DELETE_BLOG"
export const STATUS_BLOG = "STATUS_BLOG"
export const FEATURE_BLOG = "FEATURE_BLOG"


//CONTRACTORS
export const ALL_CONTRACTORS = "ALL_CONTRACTORS"
export const RECENT_CONTRACTORS = "RECENT_CONTRACTORS"
export const POPULAR_CONTRACTORS = "POPULAR_CONTRACTORS"
export const SINGLE_CONTRACTOR = "SINGLE_CONTRACTOR"
export const ACTIVE_CONTRACTORS = "ACTIVE_CONTRACTORS"
export const ASSIGNED_CONTRACTORS = "ASSIGNED_CONTRACTORS"
export const CREATE_CONTRACTOR = "CREATE_CONTRACTOR"
export const CREATE_AFFILIATION = "CREATE_AFFILIATION"
export const CREATE_AWARD = "CREATE_AWARD"
export const CREATE_BADGE = "CREATE_BADGE"
export const CREATE_PROJECT = "CREATE_PROJECT"
export const DELETE_AFFILIATION = "DELETE_AFFILIATION"
export const DELETE_AWARD = "DELETE_AWARD"
export const DELETE_BADGE = "DELETE_BADGE"
export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_DOCUMENT = "DELETE_DOCUMENT"
export const ASSIGN_AREA = "ASSIGN_AREA"
export const ASSIGN_HIGHLIGHT = "ASSIGN_HIGHLIGHT"
export const ASSIGN_LANGUAGE = "ASSIGN_LANGUAGE"
export const UNASSIGN_AREA = "UNASSIGN_AREA"
export const UNASSIGN_HIGHLIGHT = "UNASSIGN_HIGHLIGHT"
export const UNASSIGN_LANGUAGE = "UNASSIGN_LANGUAGE"
export const UPDATE_CONTRACTOR = "UPDATE_CONTRACTOR"
export const CREATE_DETAILS = "CREATE_DETAILS"
export const UPDATE_DETAILS = "UPDATE_DETAILS"
export const DETAILS_CONTRACTOR = "DETAILS_CONTRACTOR"
export const DELETE_CONTRACTOR = "DELETE_CONTRACTOR"
export const STATUS_CONTRACTOR = "STATUS_CONTRACTOR"
export const FEATURE_CONTRACTOR = "FEATURE_CONTRACTOR"
export const APPROVE_CONTRACTOR = "APPROVE_CONTRACTOR"
export const REJECT_CONTRACTOR = "REJECT_CONTRACTOR"

export const ALL_CONTRACTOR_REQUEST = "ALL_CONTRACTOR_REQUEST"
export const SINGLE_CONTRACTOR_REQUEST = "SINGLE_CONTRACTOR_REQUEST"
export const DELETE_CONTRACTOR_REQUEST = "DELETE_CONTRACTOR_REQUEST"
export const ACCEPT_CONTRACTOR_REQUEST = "ACCEPT_CONTRACTOR_REQUEST"
export const REJECT_CONTRACTOR_REQUEST = "REJECT_CONTRACTOR_REQUEST"


//COST GUIDES
export const ALL_COSTGUIDES = "ALL_COSTGUIDES"
export const SINGLE_COSTGUIDE = "SINGLE_COSTGUIDE"
export const CREATE_COSTGUIDE = "CREATE_COSTGUIDE"
export const UPDATE_COSTGUIDE = "UPDATE_COSTGUIDE"
export const DELETE_COSTGUIDE = "DELETE_COSTGUIDE"
export const STATUS_COSTGUIDE = "STATUS_COSTGUIDE"
export const FEATURE_COSTGUIDE = "FEATURE_COSTGUIDE"
